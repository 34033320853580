import React, { useEffect, useState } from 'react';
import {
  ChakraProvider,
  Box,
  theme,
} from '@chakra-ui/react';
import {
  BrowserRouter as Router, Switch, Route, Redirect
} from "react-router-dom";
// import { Logo } from './Logo';
import Header from './components/Header';
import Wall from "./pages/Wall";
import Trace from "./pages/Trace";
import Login from "./pages/Login";
import { getFirebaseAuth } from './utility/Authenticator';
import { withAppContext } from './utility/Context';
import { onAuthStateChanged } from "firebase/auth";

function App({ vanish }) {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const auth = getFirebaseAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        vanish.setUser(user);
      } else {
        // No user signed in.
        vanish.setUser(null);
      }

      setLoaded(true);
    });

    // getAuth().onAuthStateChanged(function (user) {
    //   if (user) {
    //     // User is signed in.
    //     vanish.setUser(user);
    //   } else {
    //     // No user signed in.
    //     vanish.setUser(null);
    //   }

    //   setLoaded(true);
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function NonLoginRoute({ children, user, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          if (vanish.getUser()) {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }} />
            );
          }
          else if (loaded) {
            return (children);
          }
        }}
      />
    );
  }

  function PrivateRoute({ children, user, ...rest }) {
    return (
      <Route
        {...rest}
        render={({ location }) => {
          if (vanish.getUser()) {
            return children;
          }
          else if (loaded) {
            return (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { from: location }
                }} />
            );
          }
        }}
      />
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box minH="100vh" px="0%">
          <Header />
          <Box h="full" overflowY="auto">
            <Switch>
              <NonLoginRoute path="/login">
                <Login />
              </NonLoginRoute>
              <PrivateRoute path="/trace">
                <Trace />
              </PrivateRoute>
              <PrivateRoute path="/">
                <Wall />
              </PrivateRoute>
            </Switch>
          </Box>
        </Box>
      </Router>
    </ChakraProvider>
  );
}

export default withAppContext(App);
