import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    Button,
    Input,
    useToast,
    useDisclosure
} from '@chakra-ui/react';
import { getIdToken } from '../utility/Authenticator';
import { AddTileApi } from '../utility/Api';


const AddTile = forwardRef(({ refreshTiles }, ref) => {
    const [url, setUrl] = useState('');
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => ({
        open() {
            onOpen();
        }
    }));

    var handleUrlChange = (event) => {
        setUrl(event.target.value);
    };

    var add = () => {
        getIdToken().then((idToken) => {
            fetch(AddTileApi, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ "url": url })
            })
                .then(response => response.json())
                .then(data => {
                    toast({
                        title: data.message,
                        status: "success",
                        position: "bottom-right",
                        isClosable: true,
                    })
                    refreshTiles();
                });
        });
        onClose();
    };

    return <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Add Tile</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Input variant="flushed" placeholder="URL" onChange={handleUrlChange} />
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="blue" onClick={add} isDisabled={url === ''}>Add</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>;
});

export default AddTile;