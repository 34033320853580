import React from 'react';
import {
    Button,
    ButtonGroup,
    // IconButton
} from '@chakra-ui/react';

const Pagination = ({ totalPages, currentPage, setCurrentPage }) => {
    const generateButtons = () => {
        var items = [];

        for (var i = 0; i < totalPages; i++) {
            const page = i + 1;

            if (currentPage === page) {
                items.push(<Button key={page} mr='-px' colorScheme='blue' variant='solid'>{page}</Button>);
            }
            else {
                items.push(<Button key={page} mr='-px' onClick={() => setCurrentPage(page)}>{page}</Button>);
            }
        }

        return items;
    };

    return <ButtonGroup size='sm' isAttached variant='outline'>
        {generateButtons()}
        {/* <IconButton aria-label='Add to friends' icon={<AddIcon />} /> */}
    </ButtonGroup>;
};

export default Pagination;