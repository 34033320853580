import React, { createContext } from 'react';

const BibsContext = createContext({});

export const AppProvider = BibsContext.Provider;
export const AppConsumer = BibsContext.Consumer;

export const withAppContext = Component => props => (
    <AppConsumer>
        {state => <Component {...props} vanish={state} />}
    </AppConsumer>
);