import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    Button,
    Input
} from '@chakra-ui/react';
import { updateProfile, getCurrentUser } from '../utility/Authenticator';
import { withAppContext } from '../utility/Context';

function EditProfile({ vanish, isOpen, onClose }) {
    const [displayName, setDisplayName] = useState('');

    useEffect(() => {
        const user = vanish.getUser();

        if (user) {
            setDisplayName(user.displayName);
        }
    }, [vanish]);

    var handleDisplayNameChange = (event) => {
        setDisplayName(event.target.value);
    };

    var save = () => {
        updateProfile({ displayName: displayName }).then(() => {
            // Update successful
            // ...
            vanish.setUser(getCurrentUser());
        });
        onClose();
    };

    return <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Edit Profile</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Input variant="flushed" placeholder="Display Name" onChange={handleDisplayNameChange} value={displayName} />
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="blue" onClick={save} isDisabled={displayName === ''}>Save</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>;
}

export default withAppContext(EditProfile);
