import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    Input,
    InputGroup,
    InputRightElement,
    Button,
    VStack,
    useDisclosure,
    useToast
} from '@chakra-ui/react';
var QRCode = require('qrcode.react');


const EditTile = forwardRef(({ refreshTiles }, ref) => {
    const [url, setUrl] = useState('');
    const [title, setTitle] = useState('');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const toast = useToast();

    useImperativeHandle(ref, () => ({
        open(title, url) {
            setTitle(title);
            setUrl(url);

            onOpen();
        }
    }));
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(url);

        toast({
            title: "The link has been copied to your clipboard",
            status: "success",
            isClosable: true,
            position: "bottom-right",
          });
    };

    return <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>{title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <VStack
                    spacing={4}
                    align="center">
                    <QRCode value={url} includeMargin={true} />
                    <InputGroup size="md">
                        <Input
                            pr="4.5rem"
                            type="text"
                            value={url}
                            isReadOnly={true}
                        />
                        <InputRightElement width="4.5rem">
                            <Button h="1.75rem" size="sm" onClick={copyToClipboard}>
                                Copy
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </VStack>
            </ModalBody>
            <ModalFooter>
                {/* <Button colorScheme="blue" onClick={update} isDisabled={title === ''}>Update</Button> */}
            </ModalFooter>
        </ModalContent>
    </Modal>;
});

export default EditTile;