import React from 'react';
import {
    Flex,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    HStack,
    Avatar,
    useDisclosure,
    Spacer
} from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { logout } from "../utility/Authenticator";
import { withAppContext } from '../utility/Context';
import EditProfile from './EditProfile';
import {
    useHistory
} from "react-router-dom";

function Header({ vanish }) {
    const { isOpen: isEditProfile, onOpen: onEditProfileOpen, onClose: onEditProfileClose } = useDisclosure();
    const history = useHistory();
    var user = vanish.getUser();

    var logOut = () => {
        logout();
    };

    var getProfilePicture = () => {
        var name = (user.displayName) ? user.displayName : user.email;
        return <Avatar size="sm" name={name} />;
    };

    return (
        <Flex
            as="nav"
            align="center"
            justify="space-between"
            wrap="wrap"
            px={8}
            py={3}>
            {(user) ? <HStack spacing="24px">
                <Menu isLazy>
                    <MenuButton>{getProfilePicture()}</MenuButton>
                    <MenuList>
                        <MenuItem onClick={onEditProfileOpen}>Edit Profile</MenuItem>
                        <MenuItem onClick={() => history.push("/trace")}>View Logs</MenuItem>
                        <MenuItem onClick={logOut}>Log Out</MenuItem>
                    </MenuList>
                </Menu>
            </HStack> : null}

            <Spacer />
            <ColorModeSwitcher justifySelf="flex-end" />
            <EditProfile isOpen={isEditProfile} onClose={onEditProfileClose} />
        </Flex>
    );
}

export default withAppContext(Header);