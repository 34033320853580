import { initializeApp } from "firebase/app";
// import "firebase/auth";
import { getAuth, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, signOut } from "firebase/auth";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDMehMoXPF_6wNTAaoaiFsiw6wLehACF9E",
    authDomain: "vanish-app-4a646.firebaseapp.com",
    projectId: "vanish-app-4a646",
    storageBucket: "vanish-app-4a646.appspot.com",
    messagingSenderId: "306495960885",
    appId: "1:306495960885:web:721699600e7e08c1efbe7c",
    measurementId: "G-TXSSDP4TX1"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export function login(email, password) {

    return setPersistence(auth, browserLocalPersistence).then(() => {
        return signInWithEmailAndPassword(auth, email, password);
    })
}

export function getIdToken() {
    const currentUser = auth.currentUser;
    return (currentUser !== null) ? currentUser.getIdToken(/* forceRefresh */ true) : null;
}

export function getCurrentUser() {
    return auth.currentUser;
}

export function logout() {
    return signOut(auth);
}

export function getFirebaseAuth() {
    return auth;
}

export function updateProfile(data) {
    return updateProfile(auth.currentUser, data)
}