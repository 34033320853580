import React, { useState, forwardRef, useImperativeHandle } from 'react';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    Button,
    Input,
    useToast,
    useDisclosure
} from '@chakra-ui/react';
import { getIdToken } from '../utility/Authenticator';
import { TileApi } from '../utility/Api';


const EditTile = forwardRef(({ refreshTiles }, ref) => {
    const [tileId, setTileId] = useState('');
    const [title, setTitle] = useState('');
    const toast = useToast();

    const { isOpen, onOpen, onClose } = useDisclosure();

    useImperativeHandle(ref, () => ({
        open(tileId, title) {
            setTileId(tileId);
            setTitle(title);

            onOpen();
        }
    }));

    var handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    var update = () => {
        getIdToken().then((idToken) => {
            fetch(TileApi + '/' + tileId, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({ "title": title })
            })
                .then(response => response.json())
                .then(data => {
                    toast({
                        title: data.message,
                        status: "success",
                        position: "bottom-right",
                        isClosable: true,
                    })
                    refreshTiles();
                });
        });

        onClose();
    };

    return <Modal onClose={onClose} size="md" isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
            <ModalHeader>Edit Tile</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <Input variant="flushed" placeholder="Title" onChange={handleTitleChange} value={title} />
            </ModalBody>
            <ModalFooter>
                <Button colorScheme="blue" onClick={update} isDisabled={title === ''}>Update</Button>
            </ModalFooter>
        </ModalContent>
    </Modal>;
});

export default EditTile;