import React, { useEffect, useState, useRef } from 'react';
import {
    Wrap, WrapItem, Center, Icon, Stack, Text, Image,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    Box,
    HStack,
    Spacer,
    useToast,
    SkeletonCircle,
    SkeletonText,
    Input,
    Container
} from "@chakra-ui/react";
import { FiMoreHorizontal, FiGrid, FiEdit3, FiTrash2, FiMaximize, FiCalendar } from "react-icons/fi";
import AddTile from '../components/AddTile';
import EditTile from '../components/EditTile';
import ShowTileQr from '../components/ShowTileQr';
import { getIdToken } from '../utility/Authenticator';
import { TileApi, NextPublicHolidayApi } from '../utility/Api';
import { withAppContext } from "../utility/Context";

function Wall({ vanish }) {
    const [loading, setLoading] = useState(true);
    const [tiles, setTiles] = useState([]);
    const [filteredTiles, setFilteredTiles] = useState([]);
    const [searchQuery, setSearchQuery] = useState([]);
    const [nextPublicHoliday, setNextPublicHoliday] = useState('Retrieving..');
    const toast = useToast();
    const addTileRef = useRef();
    const editTileRef = useRef();
    const showTileQrRef = useRef();

    const tileWidth = ["140px", "180px", "180px", "180px", "180px"];
    const tileSpacing = ["16px", "31px", "31px", "31px", "31px"];

    useEffect(() => {
        let isMounted = true;

        setLoading(true);
        getTiles().then(data => {
            if (data.success && isMounted) {
                setTiles(data.tiles);
                setFilteredTiles(data.tiles);
            }

            setLoading(false);
        });

        getPublicHoliday().then(data => {
            if (data.success && isMounted) {
                const publicHoliday = data.data
                setNextPublicHoliday(publicHoliday.name + ' ' + publicHoliday.time_to)
            }
        }).catch(err => {
            console.log('public holiday err:', err)
        });

        return () => {
            isMounted = false
        }
    }, []);

    const getTiles = async () => {
        const idToken = await getIdToken();
        const response = await fetch(TileApi, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        });

        const data = await response.json();
        return data;
    };

    const getPublicHoliday = async () => {
        const idToken = await getIdToken();
        const response = await fetch(NextPublicHolidayApi, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        });

        const data = await response.json();
        return data;
    };

    const refreshTiles = () => {
        getTiles().then(data => {
            if (data.success) {
                setTiles(data.tiles);
                setFilteredTiles(data.tiles);
            }
        });
    };

    const removeTiles = async (id) => {
        const idToken = await getIdToken();
        const response = await fetch(TileApi + '/' + id, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`
            }
        });

        const data = await response.json();

        if (data.success) {
            toast({
                title: data.message,
                status: "success",
                position: "bottom-right",
                isClosable: true,
            });

            refreshTiles();
        }
    };

    const tileActions = (tile) => {
        return <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<FiMoreHorizontal />}
                variant="ghost"
                size="sm"
            />
            <MenuList>
                <MenuItem icon={<FiEdit3 />} onClick={() => editTileRef.current.open(tile._id, tile.title)}>Edit</MenuItem>
                <MenuItem icon={<FiTrash2 />} onClick={() => removeTiles(tile._id)}>Remove</MenuItem>
            </MenuList>
        </Menu>;
    };

    const handleSearchQueryChange = (event) => {
        const v = event.target.value;
        setSearchQuery(v);

        const filteredItems = tiles.filter(item => item.title.toLowerCase().includes(v.toLowerCase()));
        setFilteredTiles(filteredItems);
    };

    const openSearchEngine = (event) => {
        event.preventDefault();

        const myUrlWithParams = new URL("https://www.google.com/search");
        myUrlWithParams.searchParams.append("q", searchQuery);

        const win = window.open(myUrlWithParams.href, '_blank');
        if (win != null) {
            setSearchQuery("");
            setFilteredTiles(tiles);
            win.focus();
        }
    };

    const renderTiles = () => {
        var items = [];

        for (const tile of filteredTiles) {
            items.push(
                <WrapItem key={tile._id}>
                    <Box w={tileWidth} h="110px" borderRadius="lg" borderWidth="1px" boxShadow="md">
                        <HStack>
                            <IconButton aria-label='Search database' icon={<FiMaximize />} size="sm" variant="ghost" onClick={() => showTileQrRef.current.open(tile.title, tile.link)} />
                            <Spacer />
                            {tileActions(tile)}
                        </HStack>
                        <Center>
                            <Stack alignItems="center" overflow="hidden" px={5} as="button" onClick={() => window.open(tile.link, "_blank")}>
                                <Image src={tile.favicon} alt={tile.title} />
                                <Text noOfLines={2} fontSize="xs">{tile.title}</Text>
                            </Stack>
                        </Center>
                    </Box>
                </WrapItem>
            );
        }

        return items;
    };

    const renderPlaceholderTiles = () => {
        var items = [];

        for (var i = 0; i < 30; i++) {
            items.push(
                <WrapItem key={i}>
                    <Box w={tileWidth} h="110px" borderRadius="lg" borderWidth="1px" boxShadow="md">
                        <Center>
                            <Stack overflow="hidden" alignItems="center" px={5} pt={8}>
                                <SkeletonCircle size='7' />
                                <SkeletonText noOfLines={1} w={100} />
                            </Stack>

                        </Center>
                    </Box>
                </WrapItem>
            );
        }

        return items;
    }

    const renderPublicHolidayTile = () => {
        return (nextPublicHoliday === 'Retrieving..') ? null : 
        <WrapItem>
            <Center w={tileWidth} h="110px" borderRadius="lg" borderWidth="1px" boxShadow="md" as="button">
                <Stack align="center">
                    <Icon as={FiCalendar} w={6} h={6} />
                    <Text noOfLines={2} fontSize="xs">{nextPublicHoliday}</Text>
                </Stack>
            </Center>
        </WrapItem>;
    }

    return (
        <Container maxWidth="full">
            <Center>
                <Container style={{ position: 'absolute', top: 15, zIndex: 1000 }} w={["65%", "65%", "50%", "35%", "20%"]}>
                    <form onSubmit={openSearchEngine}>
                        <Input
                            placeholder='Search'
                            onChange={handleSearchQueryChange}
                            value={searchQuery}
                        />
                    </form>
                </Container>
            </Center>
            <Wrap spacing={tileSpacing} p={6} justify="center">
                <WrapItem>
                    <Center w={tileWidth} h="110px" borderRadius="lg" borderWidth="1px" boxShadow="md" as="button" onClick={() => addTileRef.current.open()}>
                        <Stack align="center">
                            <Icon as={FiGrid} w={6} h={6} />
                            <Text fontSize="xs">Add Tile</Text>
                        </Stack>
                    </Center>
                </WrapItem>
                {renderPublicHolidayTile()}
                {(loading) ? renderPlaceholderTiles() : renderTiles()}
                <AddTile ref={addTileRef} refreshTiles={refreshTiles} />
                <EditTile ref={editTileRef} refreshTiles={refreshTiles} />
                <ShowTileQr ref={showTileQrRef} />
            </Wrap>
        </Container>
    );
}

export default withAppContext(Wall);