import React, { useState } from 'react';
import { Box, Center, Stack, Input, Spacer, Heading, Button } from "@chakra-ui/react"
import { login } from "../utility/Authenticator";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    var handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    var handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    var submit = () => {
        login(email, password).catch((error) => {
            // var errorCode = error.code;
            var errorMessage = error.message;
            console.log('error message:', errorMessage);
        });
    };

    return (
        <Center py={20}>
            <Box justify="center" w="sm" px={5} py={8} borderRadius="lg" boxShadow="2xl">
                <Heading as="h1" size="md" py={5}>
                    Welcome back....
                </Heading>
                <Stack spacing={3}>
                    <Input variant="flushed" placeholder="Email" size="md" onChange={handleEmailChange} />
                    <Input variant="flushed" placeholder="Password" size="md" type="password" onChange={handlePasswordChange} />
                    <Spacer />
                    <Button colorScheme="blue" onClick={submit}>Login</Button>
                </Stack>
            </Box>
        </Center>
    );
}

export default Login;