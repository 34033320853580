import React, { useEffect, useState } from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Container,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Text, 
    Skeleton
} from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { getIdToken } from '../utility/Authenticator';
import { TraceApi } from '../utility/Api';
import { withAppContext } from "../utility/Context";
import Pagination from '../components/Pagination';

function Trace({ vanish }) {
    const [loading, setLoading] = useState(true);
    const [traces, setTraces] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        let isMounted = true;
        setLoading(true);

        getLogs().then(data => {
            if (data.success && isMounted) {
                console.log('logs:', data);
                setTraces(data.traces);
                setCurrentPage(data.current_page);
                setTotalPages(data.total_pages);
            }

            setLoading(false);
        });

        return () => {
            isMounted = false
        }
    }, [currentPage]);

    const getLogs = async () => {
        const idToken = await getIdToken();

        var data = {};
        if (idToken !== null) {
            const response = await fetch(TraceApi + "?page=" + currentPage, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                }
            });

            data = await response.json();
        }

        return data;
    };

    const renderTraces = () => {
        var items = [];

        for (const trace of traces) {
            items.push(
                <Tr key={trace._id}>
                    <Td>{trace.object_name}</Td>
                    <Td>{trace.operation_type}</Td>
                    <Td><Text maxW={250} isTruncated>{trace.changes.title}</Text></Td>
                    <Td>{new Date(trace.logged_at).toLocaleString()}</Td>
                </Tr>
            );
        }

        return items;
    }

    const renderPlaceholderTraces = () => {
        var items = [];

        for (var i = 0; i < 10; i++) {
            items.push(
                <Tr key={i}>
                    <Td><Skeleton height='16px' /></Td>
                    <Td><Skeleton height='16px' /></Td>
                    <Td><Skeleton height='16px' /></Td>
                    <Td><Skeleton height='16px' /></Td>
                </Tr>
            );
        }

        return items;
    };

    return (
        <Container maxW="3xl">
            <Breadcrumb separator={<FiChevronRight color='gray.500' />}>
                <BreadcrumbItem>
                    <BreadcrumbLink href='/'>Home</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem isCurrentPage>
                    <BreadcrumbLink href='/trace'>Logs</BreadcrumbLink>
                </BreadcrumbItem>
            </Breadcrumb>

            <TableContainer pt={8} pb={4} >
                <Table size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Object Name</Th>
                            <Th>Operation Type</Th>
                            <Th>Title</Th>
                            <Th>Logged At</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {(loading) ? renderPlaceholderTraces() : renderTraces()}
                    </Tbody>
                </Table>
            </TableContainer>

            <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </Container>
    );
}

export default withAppContext(Trace);