import React from 'react';
import { AppProvider, withAppContext } from './utility/Context';
import App from './App';

class VanishApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            tiles: [],
            setUser: (user) => {
                this.setState({ user: user });
            },
            getUser: () => {
                return this.state.user;
            },
            setTiles: (tiles) => {
                this.setState({ tiles: tiles});
            },
            getTiles: () => {
                return this.state.tiles;
            }
        }
    }

    render() {
        return (
            <AppProvider value={this.state}>
                <App />
            </AppProvider>
        );
    }
}

export default withAppContext(VanishApp);